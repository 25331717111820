import { ReactNode, useState } from "react"
import { FiTrash } from "react-icons/fi"
import { DeleteListingModal_post } from "__generated__/DeleteListingModal_post"
import DeleteListingModal from "./DeleteListingModal"

type DeleteListingButtonProps = {
    node?: ReactNode
    post: DeleteListingModal_post
}

const DeleteListingButton = (props: DeleteListingButtonProps) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <DeleteListingModal
                isOpen={isOpen}
                hideModal={() => setIsOpen(false)}
                post={props.post}
            />
            <div
                onClick={event => {
                    setIsOpen(true)
                    event.stopPropagation()
                    event.preventDefault()
                }}
            >
                {props?.node || <FiTrash size={24} />}
            </div>
        </>
    )
}

export default DeleteListingButton
