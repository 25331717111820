import { gql, useQuery } from "@apollo/client"
import styled from "@emotion/styled"
import { SingleSelect, TextAreaField, theme } from "@pallet-hq/hegel"
import ConnectToSlackButton from "page-components/list/ListAdministration/SocialIntegrations/ConnectToSlack"
import { FiHash } from "react-icons/fi"
import { usePalletContext } from "utils/hooks/usePalletContext"
import {
    SlackChannels,
    SlackChannels_applylist_slack_channelEdges,
} from "__generated__/SlackChannels"

const SLACK_CHANNEL_QUERY = gql`
    query SlackChannels($slug: String!) {
        applylist(slug: $slug) {
            ... on ApplylistType {
                slack {
                    channelEdges(excludeArchived: true) {
                        channels {
                            name
                            id
                        }
                    }
                }
                ...ConnectToSlack_applylist
            }
        }
    }
    ${ConnectToSlackButton.fragment}
`

const SHARE_TO_SLACK_MUTATION = gql`
    mutation ShareToSlack(
        $channelId: String!
        $palletSlug: String!
        $message: String
    ) {
        shareListingInSlack(
            channelId: $channelId
            palletSlug: $palletSlug
            message: $message
        ) {
            ok
            error
        }
    }
`
const mapChannelsToValues = (
    edges: SlackChannels_applylist_slack_channelEdges
) => {
    return edges.channels!.map(e => ({
        value: e!.id!,
        label: e!.name!,
        icon: <FiHash size={16} />,
    }))
}
const SlackShare = () => {
    const { pallet } = usePalletContext()
    const { loading, data } = useQuery<SlackChannels>(SLACK_CHANNEL_QUERY, {
        variables: { slug: pallet! },
    })
    if (loading) return <></>
    return (
        <>
            {!data?.applylist?.slack?.teamName && data?.applylist && (
                <ConnectToSlackButton applylist={data.applylist} />
            )}
            {data?.applylist?.slack?.channelEdges?.channels && (
                <ChannelInput>
                    <SingleSelect
                        name="channelId"
                        label="Channel"
                        options={mapChannelsToValues(
                            data?.applylist?.slack.channelEdges
                        )}
                    />
                </ChannelInput>
            )}

            <TextAreaField
                rows={8}
                name="slackMessage"
                label="Message"
                mt={2}
                width={1}
            />
        </>
    )
}

const ChannelInput = styled.div`
    margin-bottom: ${theme.space.small};
`

SlackShare.mutation = SHARE_TO_SLACK_MUTATION
export default SlackShare
