import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import { Text, theme } from "@pallet-hq/hegel"
import LinkButton from "components/Link/LinkButton"
import config from "config"
import { SLACK_BOT_SCOPES, SLACK_USER_SCOPES } from "constants/slack"
import { FiCheck, FiSlack } from "react-icons/fi"
import { ConnectToSlack_applylist } from "__generated__/ConnectToSlack_applylist"
import useConnectSlack from "../useConnectSlack"

const ConnectToSlackFragment = gql`
    fragment ConnectToSlack_applylist on ApplylistType {
        slug
        slack {
            teamName
        }
    }
`

type ConnectToSlackProps = {
    applylist: ConnectToSlack_applylist
}

const ConnectToSlackButton = (props: ConnectToSlackProps) => {
    useConnectSlack()

    return props.applylist.slack ? (
        <Background>
            <StyledSlackIcon size={24} />
            <Text color="black" variant="uiSmall600">
                Connected to {props.applylist.slack.teamName}
            </Text>
            <GreenCheck size={24} />
        </Background>
    ) : (
        <div>
            <LinkButton
                variant="secondary"
                href={encodeURI(
                    `https://slack.com/oauth/v2/authorize?scope=${SLACK_BOT_SCOPES}&user_scope=${SLACK_USER_SCOPES}&client_id=${config.SLACK_CLIENT_ID}&redirect_uri=${config.SERVER_URL}/authorize/slack/${props.applylist.slug}`
                )}
            >
                Connect to Slack
            </LinkButton>
        </div>
    )
}

const StyledSlackIcon = styled(FiSlack)`
    margin-right: ${theme.space.medium};
    stroke: #4a154b;
`

const GreenCheck = styled(FiCheck)`
    stroke: ${theme.colors.green};
    margin-left: ${theme.space.small};
`

const Background = styled.div`
    box-sizing: border-box;
    background: ${theme.colors.gray100};
    border: 1px solid ${theme.colors.gray400};
    display: flex;
    padding: ${theme.space.small};
    align-items: center;
    width: 50%;
    border-radius: 4px;
`

ConnectToSlackButton.fragment = ConnectToSlackFragment

export default ConnectToSlackButton
