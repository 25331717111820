import styled from "@emotion/styled"
import { mq, Text, theme } from "@pallet-hq/hegel"
import useLoginModal from "state/useLoginModal"

const PermissionDenied = () => {
    const { showLoginModal } = useLoginModal()

    return (
        <EmptyListWrapper>
            <Text variant="paragraphLarge">
                You don’t have permission to view these jobs. Make sure you are{" "}
                <StyledText
                    as="span"
                    variant="paragraphLarge"
                    color="red"
                    onClick={showLoginModal}
                >
                    logged in
                </StyledText>
            </Text>
        </EmptyListWrapper>
    )
}

const StyledText = styled(Text)`
    cursor: pointer;
`

const EmptyListWrapper = styled.div`
    border-radius: 4px;
    margin-bottom: ${theme.space.medium};
    ${mq({ padding: [theme.space.small, theme.space.medium] })};
    background: ${theme.colors.gray100};
`

export default PermissionDenied
