import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import ShareListingModal from "components/Modals/ShareListingModal"
import { MouseEvent, useState } from "react"
import { FiShare } from "react-icons/fi"
import { ShareToButton_post } from "__generated__/ShareToButton_post"

const ShareToButtonFragment = gql`
    fragment ShareToButton_post on PostType {
        ...ShareListing_post
    }
    ${ShareListingModal.fragment}
`

type ShareToButtonProps = {
    post: ShareToButton_post
    canEdit: boolean
    message?: string
}

const ShareToButton = (props: ShareToButtonProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <StyledRow
            onClick={(e: MouseEvent) => {
                e.stopPropagation()
                e.preventDefault()
                setIsOpen(true)
            }}
        >
            <ShareListingModal
                canEdit={props.canEdit}
                post={props.post}
                isOpen={isOpen}
                hideModal={() => setIsOpen(false)}
            />
            <StyledShareIcon size={24} />
        </StyledRow>
    )
}

const StyledShareIcon = styled(FiShare)`
    display: inline;
`

const StyledRow = styled.div`
    position: relative;
    display: block;
    width: 100%;
`

ShareToButton.fragment = ShareToButtonFragment

export default ShareToButton
