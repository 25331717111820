import { theme } from "@pallet-hq/hegel"
import { DesktopOnly, MobileOnly } from "components/atoms"
import ContentLoader from "react-content-loader"

const MobileSkeleton = () => (
    <MobileOnly>
        <ContentLoader
            speed={2}
            width="100%"
            height={202}
            viewBox="0 0 400 202"
            backgroundColor={theme.colors.gray200}
            foregroundColor={theme.colors.gray400}
            stroke="none"
            display="block"
        >
            <circle cx="47" cy="47" r="31" />
            <rect x="16" y="94" rx="8" ry="8" width="182" height="20" />
            <rect x="16" y="130" rx="8" ry="8" width="80" height="20" />
            <rect x="16" y="166" rx="8" ry="8" width="50" height="20" />
            <rect x="82" y="166" rx="8" ry="8" width="70" height="20" />
        </ContentLoader>
    </MobileOnly>
)

const DesktopSkeleton = () => (
    <DesktopOnly>
        <ContentLoader
            speed={2}
            width={400}
            height={110}
            viewBox="0 0 400 110"
            backgroundColor={theme.colors.gray200}
            foregroundColor={theme.colors.gray400}
            stroke="none"
            display="block"
        >
            <circle cx="71" cy="47" r="31" />
            <rect x="117" y="16" rx="8" ry="8" width="182" height="20" />
            <rect x="117" y="57" rx="8" ry="8" width="80" height="20" />
            <rect x="213" y="57" rx="8" ry="8" width="50" height="20" />
            <rect x="279" y="57" rx="8" ry="8" width="70" height="20" />
        </ContentLoader>
    </DesktopOnly>
)

const JobItemSkeleton = () => (
    <>
        <MobileSkeleton />
        <DesktopSkeleton />
    </>
)

export default JobItemSkeleton
