import { gql } from "@apollo/client"

export const MANAGE_LISTING_MUTATION = gql`
    mutation ManageListingMutation(
        $uuid: UUID!
        $curatorComment: String
        $deadlineExtension: Int
        $applylist: String
        $hide: Boolean
        $featured: Boolean
    ) {
        manageListing(
            uuid: $uuid
            curatorComment: $curatorComment
            deadlineExtension: $deadlineExtension
            hide: $hide
            featured: $featured
        ) {
            listing {
                id
                curatorComment
                deadline
                isHidden
            }
            post {
                id
                deadline(applylist: $applylist)
                isHidden(applylist: $applylist)
                isFeatured(applylist: $applylist)
            }
            userError {
                message
                path
            }
        }
    }
`
