import { gql, useLazyQuery } from "@apollo/client"
import styled from "@emotion/styled"
import { mq, theme } from "@pallet-hq/hegel"
import { InfoWithIcon } from "components/InfoWithIcon"
import { useEffect } from "react"
import { FiBarChart2, FiEye, FiSmile } from "react-icons/fi"
import { formatLargeInt } from "utils/number"
import { JobStatsQuery } from "__generated__/JobStatsQuery"

const JOB_STATS_QUERY = gql`
    query JobStatsQuery(
        $clickEventValues: [String]!
        $applyEventValues: [String]!
        $impressionEventValues: [String]!
    ) {
        jobClickTotals(values: $clickEventValues) {
            count
        }
        jobApplyTotals(values: $applyEventValues) {
            count
        }
        jobImpressionTotals(values: $impressionEventValues) {
            count
        }
    }
`

type JobStatsProps = {
    listingUuids: string[]
}

type AnalyticsResult = {
    count: number | null
}

const getMetricProps = (
    value: number,
    shortDescription: string,
    longDescription: string
) => {
    if (!value) {
        return {
            text: "N/A",
            tooltip: "Not enough data",
        }
    }

    return {
        text: `${formatLargeInt(value)} ${shortDescription}`,
        tooltip: `${value} ${longDescription}`,
    }
}

export const calculateSum = (results: (AnalyticsResult | null)[]): number => {
    return results.reduce((total, current) => total + (current?.count || 0), 0)
}

const JobStats = ({ listingUuids }: JobStatsProps) => {
    const [loadStats, { loading, data, error }] = useLazyQuery<JobStatsQuery>(
        JOB_STATS_QUERY,
        {
            variables: {
                clickEventValues: listingUuids,
                applyEventValues: listingUuids,
                impressionEventValues: listingUuids,
            },
        }
    )

    useEffect(() => {
        loadStats()
    }, [loadStats])

    if (loading || error || !data) return null

    return (
        <Wrapper>
            <InfoWithIcon
                icon={FiSmile}
                color={theme.colors.gray600}
                {...getMetricProps(
                    calculateSum(data.jobImpressionTotals || []),
                    "impressions",
                    "unique users have seen this job"
                )}
            />
            <InfoWithIcon
                icon={FiEye}
                color={theme.colors.gray600}
                {...getMetricProps(
                    calculateSum(data.jobClickTotals || []),
                    "job clicks",
                    "unique job opening clicks"
                )}
            />
            <InfoWithIcon
                icon={FiBarChart2}
                color={theme.colors.gray600}
                {...getMetricProps(
                    calculateSum(data.jobApplyTotals || []),
                    "apply clicks",
                    "unique job application clicks"
                )}
            />
        </Wrapper>
    )
}

export default JobStats

const Wrapper = styled.div`
    display: flex;
    margin-top: ${theme.space.xsmall};
    ${mq({ marginLeft: [0, 78] })};
`
