import TooltipButton from "components/Buttons/TooltipButton"
import moment from "moment"
import { useState } from "react"
import { FiClock } from "react-icons/fi"
import { UpdateDateModal_post } from "__generated__/UpdateDateModal_post"
import UpdateDateModal from "./UpdateDateModal"

type UpdateDateButtonProps = {
    post: UpdateDateModal_post
}

const UpdateDateButton = (props: UpdateDateButtonProps) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <UpdateDateModal
                isOpen={isOpen}
                hideModal={() => {
                    setIsOpen(false)
                }}
                post={props.post}
            />
            <TooltipButton
                delay={0}
                tooltip={`Expires ${moment(props.post.deadline)
                    .endOf("day")
                    .fromNow()}`}
                onClick={(e: MouseEvent) => {
                    setIsOpen(true)
                    e.stopPropagation()
                    e.preventDefault()
                }}
            >
                <FiClock size={24} />
            </TooltipButton>
        </>
    )
}

export default UpdateDateButton
