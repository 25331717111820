import config from "config"
import dedent from "ts-dedent"
import { ShareListing_post } from "__generated__/ShareListing_post"

export const getSlackMessage = (applylist: string, post: ShareListing_post) => {
    return dedent`
        Title: ${post.jobTitle}
        Company: ${post.company.name}
        Website: ${post.company.website}

        Apply here: ${post.applyLink}

        Description: https://${applylist}.${config.PALLET_DOMAIN}/jobs/${post.listingUuid}

        Visit https://${applylist}.${config.PALLET_DOMAIN}/jobs/create/pricing to post a job
    `
}
