import styled from "@emotion/styled"
import { Text, TextInputField, theme } from "@pallet-hq/hegel"
import { VerticallyCenteredRow } from "components/atoms"
import { onShareClick } from "components/Buttons/ShareButton"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { ShareListing_post } from "__generated__/ShareListing_post"

type ClipboardShareProps = {
    post: ShareListing_post
}

const ClipboardShare = ({ post }: ClipboardShareProps) => {
    const { pallet } = usePalletContext()
    const postUrl = `${window.location.origin}/list/${pallet!}/jobs/${
        post.listingUuid
    }`

    return (
        <VerticallyCenteredRow>
            <TextInputField name="link" label="" value={postUrl} />
            <StyledText
                variant="uiSmall600"
                textTransform="uppercase"
                color="gray600"
                onClick={() => onShareClick(postUrl)}
            >
                Copy
            </StyledText>
        </VerticallyCenteredRow>
    )
}

export default ClipboardShare

const StyledText = styled(Text)`
    margin-left: ${theme.space.small};
    cursor: pointer;
`
