import { MouseEvent } from "react"
import { ShareListing_post } from "__generated__/ShareListing_post"

const formatTwitterUrl = (
    positionTitle: string,
    listingLink: string,
    companyTwitter?: string,
    companyName?: string
) => {
    const baseUrl = "https://twitter.com/intent/tweet?text="
    const message = `Excited to share that ${
        "@" + (companyTwitter ? companyTwitter : " " + companyName)
    } is hiring for a ${positionTitle}.\n\n${listingLink}`
    return `${baseUrl}${encodeURIComponent(message)}`
}

export const shareToTwitter = (
    e: MouseEvent,
    post: ShareListing_post,
    applylist: string
) => {
    e.stopPropagation()
    e.preventDefault() // make sure click does not propagate

    window.open(
        formatTwitterUrl(
            post.jobTitle,
            `${window.location.origin}/list/${applylist}/jobs/${post.listingUuid}`,
            post.company.twitter.replace(
                RegExp("^https?:\\/\\/(www\\.)?twitter.com\\/"),
                ""
            ),
            post.company.name
        )
    )
}
