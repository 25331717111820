import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import { mq, theme } from "@pallet-hq/hegel"
import { FlexColumn } from "components/atoms"
import ApproveDenyButtons from "components/Buttons/ApproveDeny"
import DeleteListingButton from "components/JobItem/DeleteListingButton"
import UpdateDateButton from "components/JobItem/UpdateDateButton"
import ToggleFeatureButton from "components/JobItem/ToggleFeatureButton"
import UpdateDateModal from "components/JobItem/UpdateDateButton/UpdateDateModal"
import { NewTabLink } from "components/Link/NewTabLink"
import { TRACK_EVENTS } from "constants/events"
import mixpanel from "mixpanel-browser"
import { useRouter } from "next/router"
import { useState } from "react"
import { InView } from "react-intersection-observer"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { PostOriginEnum } from "__generated__/globalTypes"
import { JobItem_post } from "__generated__/JobItem_post"
import CoreJobItem from "./CoreJobItem"
import JobStats from "./JobStats"
import ShareToButton from "./ShareToButton"
import { ToggleFeatureButtonFragment } from "components/JobItem/ToggleFeatureButton/fragments"

const JobItemFragment = gql`
    fragment JobItem_post on PostType {
        id
        canEdit
        deadline(applylist: $applylist)
        listingUuid(applylist: $applylist)
        isFeatured(applylist: $applylist)
        uuid
        originEnum
        ...CoreJobItem_post
        ...ApproveDeny_post
        matchesGlobalPreferences
        ...ShareToButton_post
        ...UpdateDateModal_post
        ...ToggleFeatureButton_post
    }
    ${CoreJobItem.fragment}
    ${ApproveDenyButtons.fragment}
    ${ShareToButton.fragment}
    ${UpdateDateModal.fragment}
    ${ToggleFeatureButtonFragment}
`

export type JobItemProps = {
    post: JobItem_post
    canEditApplylist: boolean
    forReview?: boolean
    handleClick?: () => void
}

const JobItem = (props: JobItemProps) => {
    const palletContext = usePalletContext()

    const router = useRouter()
    const [hasSeen, setHasSeen] = useState(false)

    const handleVisibilityChange = (isVisible: boolean) => {
        if (isVisible && !hasSeen) {
            mixpanel.track(TRACK_EVENTS.JOB_POST_VIEWED, {
                applylist_slug: palletContext.pallet!,
                post_uuid: props.post.uuid,
                listing_uuid: props.post.listingUuid,
                job_title: props.post.jobTitle,
            })

            setHasSeen(true)
        }
    }

    const isScraped = props.post.originEnum === PostOriginEnum.SCRAPER

    const postPath =
        (palletContext.pallet !== null
            ? `jobs/${props.post.listingUuid}`
            : `list/${palletContext.pallet!}/jobs/${props.post.listingUuid}`) +
        (palletContext.usingFallback ? `?pallet=${palletContext.pallet}` : "")

    return (
        <InView onChange={handleVisibilityChange}>
            <JobItemNewTabLink
                isFeatured={props.post.isFeatured}
                href={`${router.basePath}/${postPath}`}
                onClick={e => {
                    if (props.handleClick) props.handleClick()
                    mixpanel.track(TRACK_EVENTS.JOB_SUMMARY_CLICKED, {
                        post_uuid: props.post.uuid,
                        applylist_slug: palletContext.pallet!,
                        is_featured: props.post.isFeatured,
                        matches_preferences:
                            props.post.matchesGlobalPreferences,
                    })
                    e.stopPropagation()
                }}
            >
                <FlexColumn>
                    <CoreJobItem post={props.post} />
                    {props.canEditApplylist && (
                        <JobStats listingUuids={[props.post.listingUuid]} />
                    )}
                </FlexColumn>
                <ButtonsDiv>
                    {props.forReview ? (
                        <ApproveDenyButtons post={props.post} />
                    ) : (
                        <ActionButtonsDiv>
                            {props.canEditApplylist && (
                                <ToggleFeatureButton post={props.post} />
                            )}
                            {props.canEditApplylist && !isScraped && (
                                <UpdateDateButton post={props.post} />
                            )}
                            {props.canEditApplylist && (
                                <DeleteListingButton post={props.post} />
                            )}
                            <ShareToButton
                                post={props.post}
                                canEdit={props.canEditApplylist!}
                            />
                        </ActionButtonsDiv>
                    )}
                </ButtonsDiv>
            </JobItemNewTabLink>
        </InView>
    )
}

JobItem.fragment = JobItemFragment

const ActionButtonsDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: ${theme.space.xsmall};
`

const ButtonsDiv = styled.div`
    ${mq({ marginLeft: ["-8px", "auto"] })};
    ${mq({ marginTop: [theme.space.small, "auto"] })};
    ${mq({ marginBottom: [0, "auto"] })};
    ${mq({ visibility: ["visible", "hidden"] })};
`

const JobItemNewTabLink = styled(NewTabLink)`
    display: flex;
    flex-wrap: nowrap;
    ${mq({ flexDirection: ["column", "row"] })};

    box-sizing: border-box;
    width: 100%;
    max-width: 100vw;
    cursor: pointer;
    margin-bottom: ${theme.space.small};
    background-color: ${theme.colors.white};
    border-radius: 8px;
    padding: ${theme.space.small};
    ${mq({ borderBottom: [`1px solid ${theme.colors.gray200}`, "unset"] })};

    &:hover {
        background-color: ${(props: JobItemDivProps) =>
            props.isFeatured ? theme.colors.gold000 : theme.colors.gray100};
    }

    &:hover ${ButtonsDiv} {
        visibility: visible;
    }
`
type JobItemDivProps = {
    isFeatured: boolean | null
}

export default JobItem
