import React from "react"
import { Box, notify, theme } from "@pallet-hq/hegel"
import { FiStar } from "react-icons/fi"
import { useMutation } from "@apollo/client"
import { ManageListingMutation } from "__generated__/ManageListingMutation"
import { MANAGE_LISTING_MUTATION } from "page-components/job/mutations"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { ToggleFeatureButton_post } from "__generated__/ToggleFeatureButton_post"

type ToggleFeatureButtonProps = {
    post: ToggleFeatureButton_post
}

const ToggleFeatureButton = ({ post }: ToggleFeatureButtonProps) => {
    const { pallet } = usePalletContext()

    const [togglePostFeatured] = useMutation<ManageListingMutation>(
        MANAGE_LISTING_MUTATION,
        {
            variables: {
                uuid: post.listingUuid,
                featured: !post.isFeatured,
                applylist: pallet,
            },
            optimisticResponse: {
                manageListing: {
                    // @ts-ignore - optimistic values for all fields are not needed here
                    post: {
                        ...post,
                        isFeatured: !post.isFeatured,
                    },
                },
            },
        }
    )

    return (
        <Box
            onClick={async (e: MouseEvent) => {
                e.stopPropagation()
                e.preventDefault()
                const resp = await togglePostFeatured()
                if (resp.errors || resp.data?.manageListing?.userError) {
                    notify.fail(
                        `Failed to ${
                            post.isFeatured ? "unfeature" : "feature"
                        } role. Please try again later.`
                    )
                } else {
                    notify.success(
                        `Selected role has been ${
                            post.isFeatured ? "unfeatured" : "featured"
                        }.`
                    )
                }
            }}
        >
            {post.isFeatured ? (
                <FiStar size={24} stroke={theme.colors.gold400} />
            ) : (
                <FiStar size={24} />
            )}
        </Box>
    )
}

export default ToggleFeatureButton
