import styled from "@emotion/styled"
import { mq, Text, Theme, theme } from "@pallet-hq/hegel"
import useJobPreferences from "state/jobPreferences/useJobPreferences"

const EmptyApplyList = ({
    reason,
    showBroaden = false,
}: {
    reason: string
    showBroaden?: boolean
}) => {
    const { setJobPreferencesIsOpen } = useJobPreferences()
    return (
        <EmptyListWrapper>
            <Text variant="paragraphLarge">
                {reason}{" "}
                {showBroaden && (
                    <>
                        If you’d like to broaden your search, update them{" "}
                        <StyledText
                            as="span"
                            variant="paragraphLarge"
                            onClick={() => setJobPreferencesIsOpen(true)}
                        >
                            here
                        </StyledText>
                    </>
                )}
            </Text>
        </EmptyListWrapper>
    )
}

const StyledText = styled(Text)`
    border-bottom: ${({ theme }: { theme?: Theme }) =>
        `2px solid ${theme?.colors.primary}`};
    cursor: pointer;
`

const EmptyListWrapper = styled.div`
    border-radius: 4px;
    margin-bottom: ${theme.space.medium};
    ${mq({ padding: [theme.space.small, theme.space.medium] })};
    background: ${theme.colors.gray100};
`

export default EmptyApplyList
