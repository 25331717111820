export const SLACK_BOT_SCOPES = [
    "channels:manage",
    "channels:join",
    "channels:read",
    "chat:write",
    "im:write",
    "usergroups:read",
    "usergroups:write",
    "",
].join(",")

export const SLACK_USER_SCOPES = [
    "chat:write",
    "identify",
    "users:read",
    "users:read.email",
].join(",")
