import { gql, useMutation } from "@apollo/client"
import styled from "@emotion/styled"
import { FormModal, notify, theme } from "@pallet-hq/hegel"
import { VerticallyCenteredRow } from "components/atoms"
import { ReactComponent as SlackIcon } from "components/icons/logos/slack.svg"
import { ReactComponent as TwitterIcon } from "components/icons/logos/twitter.svg"
import { shareToTwitter } from "components/JobItem/ShareToButton/utils"
import { Formik } from "formik"
import { useState } from "react"
import { FiLink } from "react-icons/fi"
import { usePalletContext } from "utils/hooks/usePalletContext"
import * as Yup from "yup"
import { ShareListing_post } from "__generated__/ShareListing_post"
import ClipboardShare from "./ClipboardShare"
import SlackShare from "./SlackShare"
import { getSlackMessage } from "./utils"

const ShareListingModalFragment = gql`
    fragment ShareListing_post on PostType {
        id
        jobTitle
        applyLink
        company {
            id
            name
            twitter
            website
        }
        listingUuid(applylist: $applylist)
    }
`

type ShareListingModalProps = {
    isOpen: boolean
    hideModal: () => void
    post: ShareListing_post
    canEdit: boolean
}

enum ShareOptions {
    Slack = "Slack",
    Clipboard = "Clipboard",
    Twitter = "Twitter",
}

const ShareListingModal = (props: ShareListingModalProps) => {
    const [selection, setSelection] = useState<ShareOptions>(
        ShareOptions.Clipboard
    )
    const [shareToSlack] = useMutation(SlackShare.mutation)
    const { pallet } = usePalletContext()

    const handleSlackSubmit = (values: any, actions: any) => {
        if (!values.channelId?.value) {
            actions.setErrors({
                channelId: "Please choose a channel.",
            })
        }

        return shareToSlack({
            variables: {
                message: values.slackMessage,
                palletSlug: pallet!,
                channelId: values.channelId.value,
            },
        }).then(data => {
            props.hideModal()
            data.errors
                ? notify.fail("We could not send the Slack message at this.")
                : notify.success("Successfully sent your Slack message.")
        })
    }

    return (
        <Formik
            initialValues={{
                slackMessage: getSlackMessage(pallet!, props.post),
                channelId: "",
            }}
            validationSchema={Yup.object().shape({
                slackMessage: Yup.string().required("Message cannot be empty."),
            })}
            onSubmit={handleSlackSubmit}
        >
            <FormModal
                isOpen={props.isOpen}
                hideModal={props.hideModal}
                title="Share"
                showIcon={false}
                hideSubmit={selection !== ShareOptions.Slack}
                action="Send to Slack"
                width="small"
            >
                {/* Share options */}
                <IconRow>
                    <CopyLinkButton
                        onClick={() => setSelection(ShareOptions.Clipboard)}
                    >
                        <FiLink size={24} />
                    </CopyLinkButton>
                    <TwitterButton
                        onClick={e => shareToTwitter(e, props.post, pallet!)}
                    />
                    {props.canEdit && (
                        <SlackButton
                            onClick={() => setSelection(ShareOptions.Slack)}
                        />
                    )}
                </IconRow>

                {selection === ShareOptions.Slack && props.canEdit && (
                    <SlackShare />
                )}
                {selection === ShareOptions.Clipboard && (
                    <ClipboardShare post={props.post} />
                )}
            </FormModal>
        </Formik>
    )
}

const IconRow = styled(VerticallyCenteredRow)`
    margin-bottom: ${theme.space.medium};
    & > :not(:first-child) {
        margin-left: ${theme.space.small};
    }
`

const TwitterButton = styled(TwitterIcon)`
    cursor: pointer;
    width: 64px;
    height: 64px;
    background: #1b9df0;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 100%;

    & > path {
        fill: #fff;
    }
`

const CopyLinkButton = styled.div`
    cursor: pointer;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background: ${theme.colors.gray200};
`

const SlackButton = styled(SlackIcon)`
    cursor: pointer;
    width: 64px;
    height: 64px;
    padding: 12px;
    box-sizing: border-box;
    background: #4a154b;
    border-radius: 100%;
    stroke: none;
`

ShareListingModal.fragment = ShareListingModalFragment

export default ShareListingModal
