import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import { mq, Text, theme } from "@pallet-hq/hegel"
import { InfoWithIcon } from "components/InfoWithIcon"
import { CompanyLink } from "page-components/atoms/AppPages"
import {
    formatJobTooltipText,
    formatJobTooltipText2,
} from "page-components/job/PostInfo/utils"
import { FiBriefcase, FiMapPin } from "react-icons/fi"
import { CoreJobItem_post } from "__generated__/CoreJobItem_post"
import CircleImagePreview from "../Image/CircleImagePreview"

const CoreJobItemFragment = gql`
    fragment CoreJobItem_post on PostType {
        uuid
        jobTitle
        isFeatured(applylist: $applylist)
        company {
            id
            name
            website
            ...CompanyImagePreview_company
        }
        salary
        locations {
            id
            shortLabel
        }
        isOpenToRemote
        experience {
            id
            shortName
        }
        workType {
            id
            name
        }
    }
    ${CircleImagePreview.companyFragment}
`

type CoreJobItemProps = {
    post: CoreJobItem_post
}

const CoreJobItem = (props: CoreJobItemProps) => {
    const location = formatJobTooltipText2(props.post.locations)
    const isRemote = props.post.isOpenToRemote
    const locationText = location
        ? `${location.text}${isRemote ? " · Remote" : ""}`
        : `${isRemote ? "Remote" : ""}`

    const experiences = formatJobTooltipText(props.post.experience)
    const workType = props.post.workType
    return (
        <CoreJobItemDiv>
            <ImageDiv>
                <CircleImagePreview {...props.post.company} />
            </ImageDiv>
            <JobInfoDiv>
                <CompanyLink href={props.post.company.website}>
                    <Text variant="uiSmall500" color={theme.colors.gray600}>
                        {props.post.company.name}
                    </Text>
                </CompanyLink>
                <JobInfoHeader>
                    <Text variant="headlineSmall" mt="xxsmall">
                        {props.post.jobTitle}
                    </Text>
                    {props.post.isFeatured && (
                        <FeaturedDiv>
                            <Text variant="uiSmall500" color="gold700">
                                Featured
                            </Text>
                        </FeaturedDiv>
                    )}
                </JobInfoHeader>
                <AdditionalInfo>
                    {(locationText || experiences || props.post.salary) && (
                        <LocationExperienceSalary>
                            {locationText && (
                                <InfoWithIcon
                                    icon={FiMapPin}
                                    text={locationText}
                                    color={theme.colors.gray900}
                                    tooltip={location?.tooltip || ""}
                                />
                            )}
                            {experiences && (
                                <InfoWithIcon
                                    icon={FiBriefcase}
                                    text={`${experiences.text}${
                                        workType ? " · " + workType.name : ""
                                    }`}
                                    color={theme.colors.gray900}
                                    tooltip={experiences.tooltip}
                                />
                            )}
                            {props.post.salary && (
                                <Text
                                    variant="paragraphMedium"
                                    color="gray900"
                                    mt="xxsmall"
                                >
                                    {props.post.salary}
                                </Text>
                            )}
                        </LocationExperienceSalary>
                    )}
                </AdditionalInfo>
            </JobInfoDiv>
        </CoreJobItemDiv>
    )
}

CoreJobItem.fragment = CoreJobItemFragment

export default CoreJobItem

const CoreJobItemDiv = styled.div`
    display: flex;
    flex-wrap: nowrap;
    ${mq({ flexDirection: ["column", "row"] })};
    box-sizing: border-box;
`

const JobInfoHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
`

const JobInfoDiv = styled.div`
    ${mq({ marginTop: [theme.space.small, "0"] })};
    ${mq({ alignSelf: ["", "center"] })};
    display: flex;
    flex-direction: column;
    gap: ${theme.space.xxsmall};
`

const FeaturedDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: ${theme.colors.orange100};
    padding: ${theme.space.xxsmall} ${theme.space.small};
    border-sizing: border-box;
    border-radius: 4px;
    width: fit-content;
    height: fit-content;
`

const ImageDiv = styled.div`
    margin-right: ${theme.space.small};
`

const AdditionalInfo = styled.div`
    display: flex;
    ${mq({ flexDirection: ["column", "row"] })};
    margin-top: -${theme.space.xxsmall};
    gap: ${theme.space.small};
`

const LocationExperienceSalary = styled.div`
    display: flex;
    flex-direction: row;

    > :not(:last-child) {
        margin-right: ${theme.space.small};
    }

    ${mq({ marginTop: [theme.space.small, "0px"] })};
`
