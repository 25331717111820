import { notify } from "@pallet-hq/hegel"
import { useHasMounted, useQueryParamAction } from "utils/hooks"

const useConnectSlack = () => {
    const hasMounted = useHasMounted()

    useQueryParamAction("slack_connected", "True", () => {
        if (hasMounted) {
            notify.success("Successfully connected slack!")
        }
    })
}

export default useConnectSlack
