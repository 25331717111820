import { gql, useMutation } from "@apollo/client"
import { Box, FormModal, notify, Text, TextInputField } from "@pallet-hq/hegel"
import { Formik } from "formik"
import moment from "moment"
import { MANAGE_LISTING_MUTATION } from "page-components/job/mutations"
import { usePalletContext } from "utils/hooks/usePalletContext"
import * as Yup from "yup"
import { ManageListingMutation } from "__generated__/ManageListingMutation"
import { UpdateDateModal_post } from "__generated__/UpdateDateModal_post"

const UpdateDateModalFragment = gql`
    fragment UpdateDateModal_post on PostType {
        id
        listingUuid(applylist: $applylist)
        jobTitle
        deadline(applylist: $applylist)
        company {
            id
            name
        }
    }
`

type UpdateDateModalProps = {
    post: UpdateDateModal_post
    isOpen: boolean
    hideModal: () => void
}

const UpdateDateModal = (props: UpdateDateModalProps) => {
    const { pallet } = usePalletContext()
    const [manageListing] = useMutation<ManageListingMutation>(
        MANAGE_LISTING_MUTATION,
        {
            onCompleted: () => {
                notify.success("Successfully extended deadline.")
            },
            onError: () => {
                notify.fail(
                    "We could not extend the deadline for this listing."
                )
            },
        }
    )

    return (
        <Formik
            initialValues={{ deadlineExtension: 0 }}
            validationSchema={Yup.object().shape({
                deadlineExtension: Yup.number()
                    .integer("Choose a whole number of days.")
                    .test(
                        "zeroOrPositive",
                        "Choose a valid number of days.",
                        deadlineExtension =>
                            !deadlineExtension || deadlineExtension >= 0
                    )
                    .required("Required"),
            })}
            onSubmit={async values => {
                manageListing({
                    variables: {
                        uuid: props.post.listingUuid,
                        deadlineExtension: values.deadlineExtension,
                        applylist: pallet!,
                    },
                })
                props.hideModal()
            }}
        >
            {({ values }) => (
                <FormModal
                    title={`Edit time remaining for ${props.post.jobTitle} @ ${props.post.company.name}`}
                    isOpen={props.isOpen}
                    hideModal={props.hideModal}
                    action="Update expiration date"
                    width="medium"
                    withCancel
                >
                    <Text variant="paragraphLarge">
                        Extend how long this job is displayed on your board.
                    </Text>
                    <TextInputField
                        type="number"
                        name="deadlineExtension"
                        label="Days"
                        width="100%"
                        mt="medium"
                        mb="small"
                    />
                    <Box mb="large">
                        <Text as="span" variant="paragraphMedium">
                            This post will expire on:{" "}
                        </Text>
                        <Text as="span" variant="uiSmall600">
                            {moment(props.post.deadline)
                                .add(values.deadlineExtension, "days")
                                .format("MMMM Do YYYY")}
                        </Text>
                    </Box>
                </FormModal>
            )}
        </Formik>
    )
}

UpdateDateModal.fragment = UpdateDateModalFragment

export default UpdateDateModal
