import config from "config"
import { gql, useMutation } from "@apollo/client"
import { Button, Modal, notify, Text } from "@pallet-hq/hegel"
import { MANAGE_LISTING_MUTATION } from "page-components/job/mutations"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { DeleteListingModal_post } from "__generated__/DeleteListingModal_post"
import { ManageListingMutation } from "__generated__/ManageListingMutation"
import { PALLET_HEAD_QUERY } from "page-components/pallet/NavbarLayout/PalletHead/queries"

const DeleteListingModalFragment = gql`
    fragment DeleteListingModal_post on PostType {
        id
        listingUuid(applylist: $applylist)
        jobTitle
        company {
            id
            name
        }
    }
`

type DeleteListingModalProps = {
    isOpen: boolean
    hideModal: () => void
    post: DeleteListingModal_post
}

const DeleteListingModal = (props: DeleteListingModalProps) => {
    const { pallet } = usePalletContext()
    const [manageListing] = useMutation<ManageListingMutation>(
        MANAGE_LISTING_MUTATION,

        {
            update(cache, { data }) {
                // find the object in the cache
                const cachedApplylistData: {
                    applylist: { id: string }
                } | null = cache.readQuery({
                    query: PALLET_HEAD_QUERY,
                    variables: { slug: pallet! },
                })
                cache.modify({
                    id: cache.identify({
                        __typename: "ApplylistType",
                        id: cachedApplylistData?.applylist.id,
                    }),
                    fields: {
                        posts: (cachedPosts = {}, { readField }) => {
                            return Object.assign({}, cachedPosts, {
                                edges: cachedPosts.edges.filter(
                                    (edge: any) =>
                                        readField("id", edge.node) !==
                                        data?.manageListing?.post?.id
                                ),
                            })
                        },
                    },
                })
                cache.evict({ id: data?.manageListing?.post?.id })
            },
            onCompleted: () => {
                notify.success("Successfully deleted listing.")
            },
            onError: () => {
                notify.fail("We could not delete this listing.")
            },
        }
    )
    return (
        <Modal
            isOpen={props.isOpen}
            hideModal={props.hideModal}
            title="Delete listing"
            width="small"
            actions={
                <>
                    <Button
                        variant="secondary"
                        size="small"
                        onClick={props.hideModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        onClick={() =>
                            manageListing({
                                variables: {
                                    uuid: props.post.listingUuid,
                                    applylist: pallet!,
                                    hide: true,
                                },
                            }).then(() => props.hideModal())
                        }
                    >
                        Delete
                    </Button>
                </>
            }
        >
            <Text variant="uiLarge600" mb={1}>
                {`Delete ${props.post.jobTitle} @ ${props.post.company.name} from your Pallet?`}
            </Text>
            <Text variant="paragraphMedium">
                If you’d like to refund the poster for the amount they paid, let
                us know at{" "}
                <a href={`mailto:${config.SUPPORT_EMAIL}`}>
                    {config.SUPPORT_EMAIL}
                </a>
            </Text>
        </Modal>
    )
}

DeleteListingModal.fragment = DeleteListingModalFragment

export default DeleteListingModal
